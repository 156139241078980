import Head from 'next/head';
import { Login } from 'modules/Auth/components/Login/Login';
import { GetServerSideProps } from 'next';
import { getDefautServerProps } from 'server/getDefaultServerProps';

function LoginPage(): JSX.Element | null {
  return (
    <>
      <Head>
        <link rel="canonical" href="/login" key="canonical" />
      </Head>
      <Login />
    </>
  );
}

export const getServerSideProps: GetServerSideProps = async ({ params, req, res }) => {
  const defautServerProps = await getDefautServerProps({ params, req, res });

  return {
    props: {
      ...defautServerProps,
      trackEventProps: {
        eventName: 'Login Page Viewed',
      },
    },
  };
};

export default LoginPage;
